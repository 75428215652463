<!--
 * @Description: 上报安装配件弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-22 14:33:52
 * @LastEditTime: 2022-07-01 09:23:47
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="上报安装配件"
    :visible.sync="reportVisible"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="loading"
    :element-loading-background="loadingBackground"
    custom-class="report-dialog"
  >
    <el-tabs @tab-click="switchPage" v-model="activeName">
      <el-tab-pane
        :label="item.combinationName"
        v-for="item in combinationList"
        :key="item.combinationId"
        :name="item.combinationName"
      >
        <tableComponent
          ref="tableComponent"
          :basicInfo="basicInfo"
          :combinationId="item.combinationId"
        ></tableComponent>
      </el-tab-pane>
    </el-tabs>

    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import tableComponent from "./tableComponent.vue";
import { printError } from "@/utils/util";
import { getCombinationList, setReportMaterial } from "@/api";

export default {
  name: "reportDialog",
  components: { tableComponent },
  data() {
    return {
      loading: false,
      loadingBackground: "rgba(255,255,255,0.5)", // loading 遮罩颜色
      clickIndex: 0,
      activeName: "",
      combinationList: []
    };
  },
  props: ["reportVisible", "basicInfo", "isReportConsume"],
  mixins: [],
  computed: {},
  watch: {
    reportVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.clickIndex = 0;
          this.$nextTick(() => {
            this.getCombinationList();
          });
        }
      }
    }
  },
  mounted() {},

  methods: {
    //获取组合
    async getCombinationList() {
      try {
        this.loading = true;
        let res = await getCombinationList({
          id: this.basicInfo.itemId
        });
        this.combinationList = res.data;
        this.activeName = this.combinationList.length
          ? this.combinationList[0].combinationName
          : "";
        // this.combinationList.map(() => {
        //   this.$nextTick(() => {
        //     this.$refs.tableComponent[this.clickIndex].getDetail();
        //   });
        // });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击筛选框
    switchPage(tab) {
      this.clickIndex = tab.index;
      this.activeName = tab.name;
    },

    //关闭弹框
    handleClose() {
      this.$emit("closeDialog", "reportVisible");
    },
    //点击确定
    handleConfirm() {
      let tableComponents = this.$refs.tableComponent;
      let combinationList = [];
      tableComponents.map(item => {
        let obj = {
          taskId: this.basicInfo.taskId,
          itemId: this.basicInfo.itemId,
          employeeId: this.basicInfo.engineerrpId,
          combinationId: item.combinationId,
          reportMaterialList: item.tableData.filter(ele => ele.expendNum)
        };
        combinationList.push(obj);
      });
      this.setReportMaterial(combinationList);
    },
    //设置上报安装配件
    async setReportMaterial(combinationList) {
      try {
        this.loading = true;
        let res = await setReportMaterial({
          combinationList,
          isReportConsume: this.isReportConsume
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.handleClose();
          this.$nextTick(() => {
            this.$refs.resetData[this.clickIndex].getDetail();
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.report-dialog {
  .el-dialog__body {
    padding: 0px 10px 10px;
    .content {
      margin-top: 15px;
      .el-form-item {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
