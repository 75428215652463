<template>
  <div
    class="content"
    v-loading="loading"
    :element-loading-background="loadingBackground"
  >
    <el-form :searchForm="searchForm" :inline="true" ref="searchForm">
      <el-form-item prop="materialName">
        <el-input
          v-model="searchForm.materialName"
          placeholder="物料名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-button type="primary" @click="handleSearch">查询</el-button>
    </el-form>
    <el-table
      border
      :data="tableData"
      highlight-current-row
      height="400px"
      ref="tableData"
    >
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="column.fieldName === 'expendNum'">
            <el-input
              v-model="row.expendNum"
              @input="
                val => {
                  handleInput(val, row);
                }
              "
            ></el-input>
          </span>
          <span v-else>{{ row[column.fieldName] }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { printError } from "@/utils/util";
import { queryReportMaterial } from "@/api";
export default {
  name: "tableComponent",
  components: {},
  data() {
    return {
      loading: false,
      loadingBackground: "rgba(255,255,255,0.5)", // loading 遮罩颜色
      searchForm: {
        materialName: ""
      },
      columnData: [
        {
          fieldName: "materialName",
          display: true,
          fieldLabel: "配件名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "materialNo",
          display: true,
          fieldLabel: "配件编码",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "stockNum",
          display: true,
          fieldLabel: "库存数量",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "expendNum",
          display: true,
          fieldLabel: "本次消耗数量",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      tableData: [],
      allList: []
    };
  },
  props: ["basicInfo", "combinationId"],
  computed: {},
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.getDetail();
    });
  },
  methods: {
    //查询上报安装配件
    async getDetail() {
      try {
        this.loading = true;
        let res = await queryReportMaterial({
          itemId: this.basicInfo.itemId,
          employeeId: this.basicInfo.engineerrpId,
          combinationId: this.combinationId
        });
        this.tableData = res.data;
        this.allList = res.data;
        this.$nextTick(() => {
          this.$refs.tableData.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleSearch() {
      let allList = this.allList;
      let tableData = allList.filter(item => {
        return item.materialName.indexOf(this.searchForm.materialName) != -1;
      });
      this.tableData = tableData;
    },
    handleInput(val, row) {
      row.expendNum = row.expendNum.replace(/[^0-9-]+/, "");
      if (val > row.stockNum) {
        row.expendNum = row.stockNum;
      }
    },
    resetData() {
      this.tableData = [];
      this.allList = [];
      this.searchForm = {
        materialName: ""
      };
    }
  },

  created() {}
};
</script>
<style scoped></style>
